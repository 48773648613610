import GlobalStyles from "./GlobalStyles";
import Yahtzee from "./static_yahtzee/Yahtzee";

const App = () => {
  return (
    <>
      <GlobalStyles />
      <Yahtzee />
    </>
  );
};

export default App;
